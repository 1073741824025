import styled from "styled-components";

export const ContainerCardPools = styled.section`
  width: 100vw;
  background-color: var(--gray-0);
  border-bottom: 2px solid var(--gray-1);

  h2 {
    padding-top: 10px;
    color: var(--pools-text);
    font-size: var(--f-size-1-title);
    font-weight: var(--font-weigth-1);
    text-align: center;
  }
`;

export const UlPool = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  margin-top: 5px;

  @media (min-width: 767px) {
    & {
      flex-direction: row;
    }
  }
`;

export const Load = styled.li`
  color: var(--brand-dashboard);
  font-size: 22px;
  font-family: var(--font-text);
  font-weight: var(--font-weigth-1);
`;
