
import dero from "../../assets/cards/DERO.png";
import dero_solo from "../../assets/cards/DERO-SOLO.png";
import xdag from "../../assets/cards/XDAG.png";
import kaspa from "../../assets/cards/KASPA.png";
import wart from "../../assets/cards/WART.png";
import tls from "../../assets/cards/TLS.png";




export const databaseImg = [
  
  {
    nome: "dero",
    img: dero,
  },
  {
    nome: "dero_solo",
    img: dero_solo,
  },
   {
    nome: "xdag",
    img: xdag,
  },
  {
    nome: "kas_solo",
    img: kaspa,
  },
  {
    nome: "wart",
    img: wart,
  },
  {
    nome: "tls",
    img: tls,
  },

];