import { HeaderContainer, Container } from "./headerLandingPageStyle";
import Logo from "../../../assets/logo.png";
import { Sling as Hamburger } from "hamburger-react";
import { useContext, useState } from "react";
//import { GiMiner as Miner, GiTwoCoins as PollCoin } from "react-icons/gi";
import {
  MdDarkMode as MoonDarkMode,
  MdLightMode as SunDarkMode,
} from "react-icons/md";
//import { BiHelpCircle as Faq } from "react-icons/bi";
import { ConfigContext } from "../../../contexts/config";
import { useTranslation } from "react-i18next";
import { LanguageSwitch } from "../../../components/LanguageSwitch/languageSwitch";

export const HeaderLandingPage = () => {
  const { darkMode, handleDarkMode } = useContext(ConfigContext);
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  return (
    <HeaderContainer>
      <Container className="container" isOpen={isOpen}>
        <div className="containerLogo">
          <img src={Logo} alt="Logo ThreePool.Tech" />
          <h1>ThreePool.Tech</h1>
        </div>
        <nav>
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            rounded
            color="#ECECEC"
          />
          <div>
            <ul>
              
              {/* <li>
                <a href="#pools">
                  <PollCoin /> Pools
                </a>
              </li> 
              
              <li>
                <a href="#server">
                  <Miner /> {t("header.connection")}
                </a>
              </li> */}


              {/* <li>
              
                <a href="#FAQ">
                  <Faq /> FAQ
                </a>
              </li> */}



              <li>
                <button onClick={() => handleDarkMode()}>
                  {!darkMode ? <MoonDarkMode /> : <SunDarkMode />}
                  {!darkMode ? t("header.mod_dark") : t("header.mod_light")}
                </button>
              </li>
              <li>
                <LanguageSwitch />
              </li>
            </ul>
          </div>
        </nav>
      </Container>
    </HeaderContainer>
  );
};
