import styled from "styled-components";
import BgLandingPage from "../../assets/bg-landingFirst.jpg";
import "animate.css";

export const LandingPageBackground = styled.div`
  width: 100vw;
  min-height: 10vh;
  background-image: url(${BgLandingPage});
  background-position-x: center;
  background-position-y: 1613px;

  main {
    padding-top: 10px;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 300px;
  }
`;

export const SectionSlogan = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  h2 {
    color: #ffffff;
    font: var(--text-slogan);
    text-align: center;
    margin-top: 40px;
    animation: fadeInLeft 0.8s ease-in;
  }

  p {
    color: #ffffff;
    font: var(--text-subSlogan);
    text-align: center;
    margin-top: 30px;
    animation: fadeInRight 1s ease-in;
  }

  button {
    border-radius: 6px;
    padding: 12px;
    margin-top: 80px;

    font: var(--text-btn);
    background-color: var(--brand-1);
    border: 2px solid transparent;
    animation: fadeInUp 1.2s ease-in;
    transition: 0.3s ease;

    a {
      color: var(--brand-2);
    }
  }

  button:hover {
    transition: 0.3s ease;
    filter: brightness(1.2);
    border: 2px solid #ffffff8a;
    box-shadow: 0px 0px 10px 3px #ffffff87;
    color: var(--black);
  }

  @media (min-width: 767px) {
    & {
      h2 {
        margin-top: 180px;
        font-size: 65px;
      }

      p {
        margin-top: 100px;
        font-size: 30px;
      }

      button {
        margin-top: 130px;
        padding: 12px 64px;
      }
    }
  }
`;
