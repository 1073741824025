import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJson from "./language/en.json";
import ptBR from "./language/ptBr.json";

i18n.use(initReactI18next).init({
  fallbackLng: "ptBR",
  interpolation: {
    escapeValues: false,
  },
  resources: {
ptBR: ptBR,
en: enJson,

//    en: enJson,
//    ptBR: ptBR,
  },
});

export default i18n;
